<template>
  <b-row>
    <b-col cols="12" sm="12">
      <b-card>
          <b-tabs pills vertical>
            <b-tab v-for="role in sortedRoles" :key="role.id" :active="role.id == 1">
              <template v-slot:title>
                {{ role.name }}
              </template>
              <h2>Permissions for {{ role.name }}</h2>
              <b-row>
                <b-col sm="4" v-for="abilityGroup in abilityGroups" :key="`${role.id}-${abilityGroup.id}`">
                  <b-card class="abilities" no-body>
                    <template v-slot:header v-b-toggle.collapse-1>
                      {{ abilityGroup.name }}
                    </template>
                    <b-collapse visible id="collapse-1">
                      <b-list-group flush>
                        <b-list-group-item href="#" @click="togglePermission(role, ability)" :variant="hasAbility(role, ability) ? 'success' : ''" v-for="ability in abilityGroup.abilities" :key="`${role.id}-${abilityGroup.id}-${ability.id}`">
                          <i class="fa" v-bind:class="{ 'fa-check-circle-o': hasAbility(role, ability), 'fa-circle-o': !hasAbility(role, ability) }"></i> {{ ability.name }} <small class="ability-description" v-if="ability.description">{{ ability.description }}</small>
                        </b-list-group-item>
                      </b-list-group>
                    </b-collapse>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Roles',
  data: () => {
    return {
      abilities: [],
      roles: [],
      abilityGroups: []
    }
  },
  computed: {
    sortedRoles () {
      return this.roles.sort((a, b) => {
        if (a.sort > b.sort) return 1
        else if (a.sort < b.sort) return -1
        else return 0
      })
    }
  },
  methods: {
    togglePermission(role, ability) {
      if (this.hasAbility(role, ability)) {
        this.$api.accounts.roles.removeAbility({ roleId: role.id, abilityId: ability.id })
          .then((response) => {
            this.$store.dispatch('global/getAbilities')
            this.fetchRoles()
          })
          .catch((err) => {
            this.$snotify.error('Error Removing Ability')
          })
      } else {
        this.$api.accounts.roles.addAbility({ roleId: role.id, abilityId: ability.id })
          .then((response) => {
            this.$store.dispatch('global/getAbilities')
            this.fetchRoles()
          })
          .catch((err) => {
            this.$snotify.error('Error Adding Ability')
          })
      }
    },
    hasAbility (role, ability) {
      return role.abilities.map(o => o.id).includes(ability.id)
    },
    fetchRoles () {
      this.$api.accounts.roles.get()
      .then((response) => {
        this.roles = response.data
      })
      .catch((err) => {
        this.$snotify.error('Error Fetching Roles')
      })
    },
    fetchAbilityGroups () {
      this.$api.accounts.abilityGroups()
      .then((response) => {
        this.abilityGroups = response.data
      })
      .catch((err) => {
        this.$snotify.error('Error Fetching Ability Groups')
      })
    }
  },
  created () {
    this.fetchRoles()
    this.fetchAbilityGroups()
  }
}
</script>

<style scoped>
a.badge {
  margin-right: 4px;
}

.abilities > .card-header {
  cursor: pointer;
}

.ability-description {
  display: block;
  margin-left: 1rem;
  font-style: oblique;
  font-weight: 300;
}
</style>
